import React, {Component} from "react";
import {DialogActions} from "@material-ui/core";
import {Button} from "react-bootstrap";
import {DateInput, FileInput, FileUploader, PhoneInput, RadioInput, TextInput} from "../../../components";
import EnumGender from "../../../enums/EnumGender";
import {Dropdown} from "primereact/dropdown";
import {MultiSelect} from "primereact/multiselect";
import RemotingService from "../../../services/remoting-service/RemotingService";
import ValidationUtil from "../../../components/form/validator/ValidationUtil";
import {FormField} from "../../../components/form";
import EnumStaffType from "../../staff/enums/EnumStaffType";
import EnumSalutation from "../../staff/enums/EnumSalutation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {onlyUnique} from "../../../utils/ArrayUtil";

export class UserModal extends Component {

    constructor(props) {
        super(props);
        this.updateMode = !!props.user;
        this.state = {
            clinicOptions: [],
            departmentOptions: [],
            specialityOptions: [],
            ...(this.updateMode ? this.createUser(props.user) : this.createEmptyUser())
        };
    }

    createEmptyUser() {
        return {
            id: null,
            photo: null,
            name: null,
            gender: null,
            salutation: null,
            clinics: [],
            departments: [],
            staffType: null,
            specialityName: null,
            email: null,
            mobilePhone: null,
            joiningDate: null,
            leaveDate: null,
            stamp: null,
            signature: null
        }
    }

    createUser(user) {
        return {
            id: user.id,
            name: user.name,
            gender: user.gender,
            salutation: user.salutation,
            clinics: user.departments.map(d => d.clinic).filter(onlyUnique),
            departments: user.departments,
            staffType: user.staffType,
            specialityName: user.speciality.name,
            email: user.email,
            mobilePhone: user.mobilePhone,
            joiningDate: user.joiningDate,
            leaveDate: user.leaveDate,
            stamp: {
                name: "stamp",
                mimeType: "image/png",
                content: user.stamp
            },
            signature: {
                name: "signature",
                mimeType: "image/png",
                content: user.signature
            }
        }
    }

    componentDidMount() {
        this.getClinics();
        if (this.updateMode) {
            this.getUserPhoto();
            this.clinicsChanged(this.state.clinics);
            this.departmentsChanged(this.state.departments);
        }
    }

    getClinics() {
        RemotingService.getRemoteCall('api/clinic/listAll', null, (result) => {
            this.setState({clinicOptions: result.items});
        });
    }

    getUserPhoto() {
        RemotingService.getRemoteCall(`api/staff/${this.props.user.id}/photo`, null, photo => {
            this.setState({photo});
        });
    }

    getDepartments() {
        const clinicIds = this.state.clinics.map(c => c.id);

        RemotingService.getRemoteCall(`api/clinic/department/list-department-contains-provider`, {clinicIds}, (result) => {
            this.setState({departmentOptions: result});
        });
    }

    getSpecialities() {
        const departmentIds = this.state.departments.map(c => c.id);

        RemotingService.getRemoteCall(`api/speciality/list`, {departmentIds}, (specialityOptions) => {
            let specialityName = (!this.updateMode && specialityOptions.length === 1) ?
                                    specialityOptions[0].name : this.state.specialityName;

            if (!specialityOptions.some(s => s.name === specialityName)) {
                specialityName = null;
            }

            this.setState({specialityOptions, specialityName});
        });
    }

    clinicsChanged(clinics) {
        this.setState(
            {
                clinics,
                departments: null,
                speciality: null
            },
            () => this.getDepartments()
        );
    }

    departmentsChanged(departments) {
        this.setState(
            {
                departments,
                speciality: null
            },
            () => this.getSpecialities()
        );
    }

    save() {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        const params = {
            photo: this.state.photo,
            name: this.state.name,
            gender: this.state.gender,
            salutation: this.state.salutation,
            departments: this.state.departments,
            staffType: this.state.staffType,
            speciality: {name: this.state.specialityName},
            email: this.state.email,
            mobilePhone: this.state.mobilePhone,
            joiningDate: this.state.joiningDate,
            leaveDate: this.state.leaveDate,
            stamp: this.state.stamp?.content,
            signature: this.state.signature?.content
        }

        const url = this.updateMode ? `api/staff/${this.props.user.id}/update` : 'api/staff/create';
        RemotingService.postRemoteCall(url, params, () => {
            this.props.close();
            this.props.onSave();
        });
    }

    render() {
        this.formFields = [];

        return (
            <div style={{minWidth: 400}}>
                <div className="container">
                    <div className="row flex-column align-items-center mb-1">
                        <FileInput accept=".jpg, .jpeg, .png"
                                   maxFileSize={1048576}
                                   value={this.state.photo}
                                   onChange={value => this.setState({photo: value})}>
                            {this.state.photo ?
                                <div className="position-relative">
                                    <FontAwesomeIcon icon="times"
                                                     style={{position: "absolute", top: 2, right: 2, background: "rgba(100%, 100%, 100%, 0.5)"}}
                                                     onClick={e => {
                                                         e.preventDefault();
                                                         this.setState({photo: null});
                                                     }}/>
                                    <img src={"data:image;base64,"+this.state.photo.content} style={{maxWidth: "8rem", maxHeight: "8rem"}} alt=""/>
                                </div> :
                                <div className="d-flex flex-column p-justify-center align-items-center"
                                     style={{
                                        height: "8rem",
                                        width: "8rem",
                                        border: "dashed 1px",
                                        backgroundColor: "#F0F0F0"
                                    }}>
                                    <FontAwesomeIcon icon="plus" size="2x"/>
                                    <span>Upload Photo</span>
                                </div>
                            }
                        </FileInput>
                    </div>
                    <div className="row flex-column mb-1">
                        <span>Full Name</span>
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required validateOn={this.state.name}>
                            <TextInput value={this.state.name}
                                       onChange={value => this.setState({name: value})}/>
                        </FormField>
                    </div>
                    <div className="row mb-1">
                        <div className="col-6 d-flex flex-column p-0">
                            <span>Gender</span>
                            <FormField ref={formField => this.formFields.push(formField)}
                                       required validateOn={this.state.gender}>
                                <RadioInput options={EnumGender.toArray()}
                                            valueProperty="key"
                                            displayProperty="name"
                                            value={this.state.gender}
                                            onChange={value => this.setState({gender: value, salutation: null})}/>
                            </FormField>
                        </div>
                        <div className="col-6 d-flex flex-column p-0">
                            <span>Salutation</span>
                            <Dropdown options={EnumSalutation.getByGender(this.state.gender)}
                                      optionValue="key" optionLabel="name"
                                      value={this.state.salutation}
                                      onChange={e => this.setState({salutation: e.value})}/>
                        </div>
                    </div>
                    <div className="row flex-column mb-1">
                        <span>Profile</span>
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required validateOn={this.state.staffType}>
                            <Dropdown options={EnumStaffType.selectableTypes}
                                      optionValue="key" optionLabel="name"
                                      value={this.state.staffType}
                                      onChange={e => this.setState({staffType: e.value, stamp: null, signature: null})}/>
                        </FormField>
                    </div>
                    <div className="row flex-column mb-1">
                        <span>Clinic</span>
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required validateOn={this.state.clinics}>
                            <MultiSelect value={this.state.clinics}
                                         options={this.state.clinicOptions}
                                         optionLabel="name"
                                         onChange={e => this.clinicsChanged(e.value)}/>
                        </FormField>
                    </div>
                    <div className="row flex-column mb-1">
                        <span>Departments</span>
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required validateOn={this.state.departments}>
                            <MultiSelect value={this.state.departments}
                                         options={this.state.departmentOptions}
                                         optionLabel="combinedName"
                                         onChange={e => this.departmentsChanged(e.value)}/>
                        </FormField>
                    </div>
                    <div className="row flex-column mb-1">
                        <span>Speciality</span>
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required validateOn={this.state.specialityName}>
                            <Dropdown value={this.state.specialityName}
                                      options={this.state.specialityOptions}
                                      optionLabel="name" optionValue="name"
                                      onChange={e => this.setState({specialityName: e.value})}/>
                        </FormField>
                    </div>
                    <div className="row flex-column mb-1">
                        <span>Email</span>
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required email validateOn={this.state.email}>
                            <TextInput value={this.state.email}
                                       onChange={value => this.setState({email: value})}/>
                        </FormField>
                    </div>
                    <div className="row flex-column mb-1">
                        <span>Mobile Phone</span>
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required validateOn={this.state.mobilePhone}>
                            <PhoneInput value={this.state.mobilePhone}
                                        onChange={value => this.setState({mobilePhone: value})}/>
                        </FormField>
                    </div>
                    <div className="row mb-1">
                        <div className="col-6 d-flex flex-column p-0">
                            <span>Joining Date</span>
                            <FormField ref={formField => this.formFields.push(formField)}
                                       required validateOn={this.state.joiningDate}>
                                <DateInput value={this.state.joiningDate}
                                           onChange={value => this.setState({joiningDate: value})}/>
                            </FormField>
                        </div>
                        <div className="col-6 d-flex flex-column p-0">
                            <span>Exit Date</span>
                            <DateInput value={this.state.leaveDate?this.state.leaveDate:null} clearable={true}
                                       onChange={value => this.setState({leaveDate: value})}/>
                        </div>
                    </div>
                    {(this.state.staffType === EnumStaffType.DOCTOR.key || this.state.staffType === EnumStaffType.THERAPIST.key) &&
                        [
                            <div className="row flex-column mb-1">
                                <span>Stamp</span>
                                <FormField ref={formField => this.formFields.push(formField)}
                                           required validateOn={this.state.stamp}>
                                    <FileUploader maxFileSize={1048576} accept=".jpg, .jpeg, .png"
                                                  value={this.state.stamp?.content && this.state.stamp}
                                                  onChange={value => this.setState({stamp: value})}/>
                                </FormField>
                            </div>,
                            <div className="row flex-column mb-1">
                                <span>Signature</span>
                                <FormField ref={formField => this.formFields.push(formField)}
                                           required validateOn={this.state.signature}>
                                    <FileUploader maxFileSize={1048576} accept=".jpg, .jpeg, .png"
                                                  value={this.state.signature?.content && this.state.signature}
                                                  onChange={value => this.setState({signature: value})}/>
                                </FormField>
                            </div>
                        ]
                    }
                </div>
                <DialogActions>
                    <Button variant="success" onClick={() => this.save()}>{this.updateMode ? "Update" : "Save"}</Button>
                </DialogActions>
            </div>
        )
    }

}