import React, {Component} from "react";
import QueryPanel from "../../../components/query/QueryPanel";
import ResultTable from "../../../components/query/ResultTable";
import {Column} from "primereact/column";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import dialog from "../../../components/dialog/Dialog";
import {UserModal} from "./UserModal";
import EnumStaffType from "../../staff/enums/EnumStaffType";
import DateUtil from "../../../utils/DateUtil";
import {onlyUnique} from "../../../utils/ArrayUtil";
import {SelectInput} from "../../../components";
import DelayedTextInput from "../../../components/text-input/DelayedTextInput";
import RemotingService from "../../../services/remoting-service/RemotingService";
import PrimeDateInput from "../../../components/date-input/PrimeDateInput";
import AuthService from "../../../services/auth-service/AuthService";
import confirmDialog from "../../../components/dialog/ConfirmDialog";
import Tooltip from "../../../components/tooltip/Tooltip";

export default class UserSetup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clinics: [],
            specialities: [],
            queryOptions: {
                isActive: true
            }
        }
    }

    componentDidMount() {
        this.retrieveClinics();
        this.retrieveSpecialities();
    }

    retrieveClinics() {
        RemotingService.getRemoteCall('api/clinic/list', null, (result) => {
            this.setState({clinics: result.items});
        });
    }

    retrieveSpecialities() {
        RemotingService.getRemoteCall('api/speciality/list', null, (specialities) => {
            this.setState({specialities});
        });
    }

    showAddUserModal() {
        dialog({
            title: "Add User",
            component: <UserModal onSave={() => this.queryPanel.resetAndQuery()}/>
        });
    }

    inactivateUser(staff) {
        confirmDialog(
            "Confirmation",
            `Do you want to inactivate user (${staff.name})?`,
            () => {
                RemotingService.postRemoteCall(`api/staff/${staff.id}/inactivate`, null, () => {
                    this.queryPanel.resetAndQuery();
                });
            }
        );
    }

    resetPassword(staff) {
        confirmDialog(
            "Confirmation",
            `Do you want to send new password mail for user (${staff.name})?`,
            () => {
                RemotingService.postRemoteCall(`api/staff/${staff.id}/mailPasswordReset`, null, () => {
                    this.queryPanel.resetAndQuery();
                });
            }
        );
    }

    updateUser(staff) {
        dialog({
            title: "Edit User",
            component: <UserModal user={staff} onSave={() => this.queryPanel.resetAndQuery()}/>
        });
    }

    handleQueryOptionChange(key, val) {
        this.setState(
            (state) => {
                let newState = {...state};
                newState.queryOptions[key] = val;
                return newState;
            },
            () => this.queryPanel.resetAndQuery()
        );
    }

    nameTemplate = (staff) => {
        const photoSrc = staff.thumbnail ?
            'data:image/jpeg;base64,' + staff.thumbnail :
            process.env.PUBLIC_URL + '/assets/patient_profile.png';

        return (
            <div className="d-flex">
                <img src={photoSrc} width={50} height={50} alt=""/>
                <div className="d-flex align-items-center p-justify-center pl-1">{staff.name}</div>
            </div>
        )
    }

    clinicsTemplate = (staff) => {
        return staff.departments.map(d => d.clinic.name).filter(onlyUnique).join(", ");
    }

    profileTemplate = (staff) => {
        return EnumStaffType[staff.staffType]?.name;
    }

    dateTemplate = (dateStr) => {
        return DateUtil.formatDateMMM(dateStr) ?? "-";
    }

    statusTemplate = (staff) => {
        return staff.isActive ? "Active" : "Inactive";
    }

    actionsTemplate = (staff) => {
        return (
            <div className="d-flex p-justify-center">
                {staff.isActive &&
                <Tooltip title="Inactivate">
                    <FontAwesomeIcon icon="trash" size="sm" color="red" onClick={() => this.inactivateUser(staff)}/>
                </Tooltip>
                }
                <Tooltip title="Reset Password">
                    <FontAwesomeIcon icon="key" size="sm" className="ml-1" onClick={() => this.resetPassword(staff)}
                                     color={staff.passwordResetRequested ? "orange" : undefined} />
                </Tooltip>
                <Tooltip title="Edit">
                    <FontAwesomeIcon icon="edit" size="sm" className="ml-1" onClick={() => this.updateUser(staff)}/>
                </Tooltip>
            </div>
        )
    }

    nameFilter = () => {
        return <DelayedTextInput className="w-100"
                                 value={this.state.queryOptions.name}
                                 onChange={val =>
                                     this.handleQueryOptionChange("name", val)
                                 }/>
    }

    specialityFilter = () => {
        return <SelectInput className="w-100" options={this.state.specialities}
                            displayProperty="name" valueProperty="name" searchEnabled={false}
                            value={this.state.queryOptions.speciality}
                            onChange={val =>
                                this.handleQueryOptionChange("speciality", val)
                            }/>
    }

    clinicFilter = () => {
        return <SelectInput className="w-100" options={this.state.clinics}
                            displayProperty="name" valueProperty="id" searchEnabled={false}
                            value={this.state.queryOptions.clinicId}
                            onChange={val =>
                                this.handleQueryOptionChange("clinicId", val)
                            }/>
    }

    profileFilter = () => {
        return <SelectInput className="w-100" options={EnumStaffType.toArray()}
                            displayProperty="name" valueProperty="key" searchEnabled={false}
                            value={this.state.queryOptions.type}
                            onChange={val =>
                                this.handleQueryOptionChange("type", val)
                            }/>
    }

    emailFilter = () => {
        return <DelayedTextInput className="w-100"
                                 value={this.state.queryOptions.email}
                                 onChange={val =>
                                     this.handleQueryOptionChange("email", val)
                                 }/>
    }

    joiningDateFilter = () => {
        return <PrimeDateInput className="w-100" inputClassName="p-0" clearable
                               value={this.state.queryOptions.joiningDate}
                               onChange={val =>
                                   this.handleQueryOptionChange("joiningDate", DateUtil.formatDate(val, "YYYY-MM-DD"))
                               }/>
    }

    leaveDateFilter = () => {
        return <PrimeDateInput className="w-100" inputClassName="p-0" clearable
                               value={this.state.queryOptions.leaveDate}
                               onChange={val =>
                                   this.handleQueryOptionChange("leaveDate", DateUtil.formatDate(val, "YYYY-MM-DD"))
                               }/>
    }

    statusFilter = () => {
        return <SelectInput className="w-100" options={[{label: "Active Users", value: true}, {label: "Inactive Users", value: false}]}
                            displayProperty="label" valueProperty="value" searchEnabled={false}
                            value={this.state.queryOptions.isActive}
                            onChange={val =>
                                this.handleQueryOptionChange("isActive", val ?? [true, false])
                            }/>
    }

    render() {
        return (
            <div className="user-setup">
                <div className="d-flex justify-content-end mb-2">
                    {AuthService.userHasAuthority("ADD_USER") &&
                    <Button onClick={() => this.showAddUserModal()}>
                        <FontAwesomeIcon icon="plus"/> Add User
                    </Button>
                    }
                </div>
                <QueryPanel ref={el => this.queryPanel = el}
                            url="api/staff/list-all"
                            queryOptions={this.state.queryOptions}>
                    <ResultTable>
                        <Column field="name" header="Name"
                                body={this.nameTemplate}
                                filter filterElement={this.nameFilter()}/>
                        <Column field="speciality.name" header="Speciality"
                                filter filterElement={this.specialityFilter()}/>
                        <Column header="Clinic"
                                body={this.clinicsTemplate}
                                filter filterElement={this.clinicFilter()}/>
                        <Column field="staffType" header="Profile"
                                body={this.profileTemplate}
                                filter filterElement={this.profileFilter()}/>
                        <Column field="email" header="Email"
                                filter filterElement={this.emailFilter()}/>
                        <Column field="joiningDate" header="Joining Date"
                                body={staff => this.dateTemplate(staff.joiningDate)}
                                filter filterElement={this.joiningDateFilter()}/>
                        <Column field="leaveDate" header="Exit Date"
                                body={staff => this.dateTemplate(staff.leaveDate)}
                                filter filterElement={this.leaveDateFilter()}/>
                        <Column field="isActive" header="Status"
                                body={this.statusTemplate}
                                filter filterElement={this.statusFilter()}/>
                        <Column header="Actions" body={this.actionsTemplate}
                                style={{width: 70, textAlign: "center"}}/>
                    </ResultTable>
                </QueryPanel>
            </div>
        )
    }
}