import React from 'react';

const footer = () => {
    return (
        <div className="Footer">
            <hr className="FooterLine"/>
            <div className="FooterContent">
                <i><small>v1.34.5</small></i>
                <i><small>&copy; Copyright 2024 - Disc-me.com All rights reserved.</small></i>
            </div>
        </div>
    )
};

export default footer;
